@use '../../../../sass/colors';
.drawer_left {
  border-right: 1px solid colors.$color-grey-light;
  height: 100%;
  width: 100%;
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  &-data {
    margin-top: 13px;
    height: 86%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-logo {
    width: 135px;
  }
  &-helper {
    margin-top: 35px;
  }
}
