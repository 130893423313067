@use '../../../../sass/index';
@use '../../../../sass/colors';
.header {
  box-sizing: border-box;
  height: 70px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &-left {
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    &_link {
      color: colors.$color-grey-medium;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      transition: 0.3s;
      &-active {
        color: colors.$color-primary;
        text-decoration: none;
        border-bottom: 1px solid colors.$color-primary;
      }
      &-span {
        margin-left: 5px;
      }
    }
    &_link:hover {
      color: colors.$color-primary;
      text-decoration: none;
      border-bottom: 1px solid colors.$color-primary;
    }
  }
  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &_language {
      border-radius: 8px;
      @include index.black-shadow;
      margin-left: 16px;
    }
  }
}
.header-right_language .MuiOutlinedInput-input {
  padding: 7px;
}
