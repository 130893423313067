@use '../../sass/colors';
@use '../../sass/index';
.helper {
  background-color: colors.$color-green-light;
  width: 205px;
  padding: 5px 20px 15px 20px;
  @include index.black-shadow;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  &-icon {
    position: absolute;
    top: -20px;
    color: colors.$color-white;
    padding: 5px;
    background-color: colors.$color-green-light;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%);
    &-background {
      @include index.gradient;
      height: 30px;
      border-radius: 50%;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
