.basic_layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  &-left {
    height: 100%;
    width: 20%;
  }
  &-right {
    width: 80%;
    &-body {
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      padding: 0px 10px 10px;
    }
  }
}
