@use '../../../sass/colors';
@use '../../../sass/index';
.total-table {
  @include index.black-shadow;
  border-radius: 10px;
  width: 205px;
  padding: 5px 20px 15px 20px;
  box-sizing: border-box;
  &_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    &-img {
      width: 30px;
    }
    &_totals {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      &-value {
        color: colors.$color-primary;
      }
      &-unit {
        color: colors.$color-grey-medium;
        font-size: 12px;
      }
    }
  }
}
