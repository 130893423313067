@use '../../sass/colors';
.loading {
  width: 100%;
  height: calc(100vh - 134px);
  display: flex;
  align-content: center;
  justify-content: center;
  &-elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-animation {
      position: relative;
      &_png {
        width: 130px;
      }
      &_spinner-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -51%);
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        z-index: -1;
      }
      &_circle-border {
        width: 150px;
        height: 150px;
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: linear-gradient(
          0deg,
          white 33%,
          colors.$color-secondary 90%,
          colors.$color-primary 100%
        );
        animation: spin 0.9s linear 0s infinite;
      }
      &_circle-core {
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 50%;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
