@use '../../../sass/colors';
.input-search {
  height: 39px !important;
  width: 200px !important;
  background-color: colors.$color-grey-light;
  color: colors.$color-black;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  &_data {
    margin-left: 10px;
  }
}
