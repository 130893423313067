@use '../../../sass/_colors';
.basic-label-input {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: colors.$color-black;
  font-weight: 600;
  &__primary {
    font-weight: 500;
  }
  &__comments {
    margin-left: 3px;
    font-weight: 300 !important;
  }
  &__special {
    margin-left: 3px;
    font-weight: 700;
    color: colors.$color-primary;
  }
}
