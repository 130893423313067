@use '../../../sass/colors';
.selector {
  border-radius: 5px;
  border: 1px solid colors.$color-grey-medium;
  color: colors.$color-grey;
  cursor: pointer;
}
.selector:hover,
.selector_selected {
  border-radius: 5px;
  border: 1px solid colors.$color-primary;
  color: colors.$color-primary;
  background-color: rgba(colors.$color-primary, 0.2);
}
