@use '../../../sass/colors';
.button-rounded {
  border-radius: 50px;
  cursor: pointer;
}
.btn-rounded-primary {
  color: colors.$color-white;
  background-color: colors.$color-primary;
  border: solid 1px transparent;
  transition: 0.3s;
  &:hover {
    background-color: lighten(colors.$color-primary, 5%);
  }
}
.btn-rounded-secondary {
  color: colors.$color-primary;
  border: solid 1px colors.$color-primary;
  background-color: colors.$color-white;
  transition: 0.3s;
  &:hover {
    color: darken(colors.$color-primary, 5%);
    background-color: rgba(colors.$color-primary, 0.1);
    border: solid 1px darken(colors.$color-primary, 5%);
  }
}
.btn-rounded-disable {
  color: colors.$color-white;
  border: solid 1px colors.$color-grey;
  background-color: colors.$color-grey;
  transition: 0.3s;
  cursor: not-allowed;
}

.button-rounded_span {
  display: flex;
  align-items: center;
}
