@use '../../../sass/_colors.scss';

.basic-input {
  display: flex;
  flex-direction: column;
  &__box {
    height: 39px;
    box-sizing: border-box;
    border: 1px solid colors.$color-grey-medium;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    position: relative;
  }
}
.basic-input-error {
  display: flex;
  flex-direction: column;
  text-align: start;
  &__box {
    height: 39px;
    box-sizing: border-box;
    border: 1px solid colors.$color-alert;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__error {
    text-align: start;
    font-size: 10px;
    color: colors.$color-alert;
    height: 15px;
  }
}
.basic-input__message {
  text-align: start;
  font-size: 10px;
}
