@use '../../../sass/colors';
.basic-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &_label {
    font-size: small;
  }
}
