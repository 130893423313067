$color-primary: #00c8b4;
$color-secondary: #82e682;
$color-danger: #e5363a;
$color-alert: #990063;
$color-white: #fdfdfd;
$color-black: #0a5064;
$color-grey: #8d949a;
$color-grey-light: #e9f0f3;
$color-grey-medium: #6c7784;
$color-grey-disabled: #c5c5c5;
$color-green-light: #dcf5f0;

// $background-gradient: linear-gradient(145deg, rgba(10,180,149,1) 0%, rgba(93,219,196,1) 15%, rgba(171,216,124,1) 25%, rgba(141,198,88,1) 50%, rgba(30,193,161,1) 75%, rgba(93,219,196,1) 100%);
// $green-gradient: linear-gradient(-45deg, $color-primary, $color-secundary);
// $border-green-gradient: linear-gradient(-45deg, $color-primary, $color-secundary);

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorBlack: $color-black;
  colorGreyLight: $color-grey-light;
  colorGreyMedium: $color-grey-medium;
  colorAlert: $color-alert;
}
