.modal {
  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_close {
      font-size: 2rem !important;
      display: flex;
      cursor: pointer;
    }
  }
  &-actions {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    column-gap: 1rem;
  }
}
.modal-header_close:hover {
  animation: amplitude 0.2s ease-in;
  animation-fill-mode: forwards;
}
@keyframes amplitude {
  100% {
    transform: scale(110%);
  }
}
