@use '../../../sass/colors';
.actions {
  // width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 0.3rem;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  &_action {
    border: 1px solid colors.$color-grey;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-disabled {
      background-color: colors.$color-grey-disabled;
      color: colors.$color-white;
      cursor: not-allowed;
      border: 1px solid colors.$color-grey-disabled;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_primary {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$color-primary;
  }
  .actions_action:hover {
    color: colors.$color-primary;
    background-color: rgba(colors.$color-primary, 0.2);
    border-color: colors.$color-primary;
  }
}
