@use '../../../sass/index';
.demo {
  padding: 13px 0;
  border-radius: 0 13px 13px 0;
  @include index.black-shadow;
  z-index: 3;
  background-color: white;
  &-img {
    width: 54px;
  }
}
