@use '../../../sass/colors';
@use '../../../sass/index';
.navigation_btn {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: colors.$color-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$color-white;
  @include index.black-shadow;
}
