@use '../../../../sass/colors';

.btn {
  border: 1px solid colors.$color-grey;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-disable {
    background-color: colors.$color-grey-disabled;
    color: colors.$color-white;
    cursor: not-allowed;
    border: 1px solid colors.$color-grey-disabled;
  }
}
